import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.multiplaSelecao)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          class: "ui-full",
          value: _ctx.computedCodigos,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigos) = $event)),
          "show-search": "",
          mode: "multiple",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          filterOption: false,
          "not-found-content": _ctx.state.buscando ? undefined : null,
          disabled: _ctx.props.disabled,
          onChange: _ctx.changeMultiplos,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pesquisar(''))),
          onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pesquisar($event))),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.digitando = false))
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.itensBuscaRapida, (cliente) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: cliente.codigo,
                value: cliente.codigo
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.montaApresentacaoCliente(cliente)), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.state.buscando)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "disabled", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          class: "ui-full",
          value: _ctx.computedCodigo,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedCodigo) = $event)),
          "show-search": "",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          filterOption: false,
          "not-found-content": _ctx.state.buscando ? undefined : null,
          disabled: _ctx.props.disabled,
          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.change())),
          onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.pesquisar(''))),
          onSearch: _cache[7] || (_cache[7] = ($event: any) => (_ctx.pesquisar($event))),
          onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.state.digitando = false))
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.itensBuscaRapida, (cliente) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: cliente.codigo,
                value: cliente.codigo
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.montaApresentacaoCliente(cliente)), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.state.buscando)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "disabled"]))
  ]))
}