
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import {
  ITabelaPreco, ITabelaPrecoRegiao, ITabelaPrecoEstado, ITabelaPrecoCliente,
  ITabelaPrecoItem,
  ITabelaPrecoCidade,
} from '@/models/Entidades/ITabelaPreco';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import ServicoTabelaPreco from '@/servicos/ServicoTabelaPreco';
import { IResposta } from '@/core/models/IResposta';
import Icone from '@/core/components/UI/Icone.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import ComboCliente from '@/components/ComboCliente.vue';
import ComboRegiao from '@/components/ComboRegiao.vue';
import ComboEstado from '@/components/ComboEstado.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import ComboProdutoDefinicao from '@/components/Produtos/ComboProdutoDefinicao.vue';
import CaixaSelecao from '@/core/components/UI/CaixaSelecao.vue';
import { ETipoTabelaPreco } from '@/models/Enumeradores/ETipoTabelaPreco';
import { ICaixaSelecao } from '@/core/models/Tela/ICaixaSelecao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'TabelaPrecosCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    CampoNumerico,
    ComboCliente,
    ComboRegiao,
    ComboEstado,
    ComboCidade,
    ComboProdutoDefinicao,
    CaixaSelecao,
    Icone,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota, apresentarMensagemAlerta,
    } = useAppBase();

    const servicoTabelaPreco = new ServicoTabelaPreco();

    const state = reactive({
      tabelaPreco: {} as ITabelaPreco,
      clientesSelecionados: [] as number[],
      regioesSelecionadas: [] as number[],
      estadosSelecionados: [] as number[],
      cidadesSelecionadas: [] as number[],
      opcoesCaixaSelacao: [] as ICaixaSelecao[],
      novoItemPreco: {} as ITabelaPrecoItem,
    });

    function limparItemTabelaPreco() {
      state.novoItemPreco = {} as ITabelaPrecoItem;
      state.novoItemPreco.codigo = 0;
      state.novoItemPreco.codigoTabelaPreco = 0;
      state.novoItemPreco.codigoProduto = 0;
      state.novoItemPreco.codigoProdutoDefinicao = 0;
      state.novoItemPreco.preco = 0;
      state.novoItemPreco.removido = false;
      state.novoItemPreco.alterado = false;
    }

    function adicionarItemTabelaPreco() {
      if (!UtilitarioGeral.validaCodigo(state.novoItemPreco.codigoProdutoDefinicao)) {
        apresentarMensagemAlerta('Selecione um produto para adicionar na tabela de preço!');
        return;
      }

      if (UtilitarioGeral.validaLista(state.tabelaPreco.itens)) {
        const index = state.tabelaPreco.itens.findIndex((c) => c.codigoProdutoDefinicao === state.novoItemPreco.codigoProdutoDefinicao);
        if (index >= 0) {
          apresentarMensagemAlerta('O produto selecionado já se encontra na tabela de preço!');
          return;
        }
      }

      state.tabelaPreco.itens.push(UtilitarioGeral.instanciaObjetoLocal(state.novoItemPreco));
      limparItemTabelaPreco();
    }

    function itemAlterado(index:number) {
      state.tabelaPreco.itens[index].alterado = true;
    }

    function removerItemTabelaPreco(index:number) {
      if (UtilitarioGeral.validaCodigo(state.tabelaPreco.itens[index].codigo)) {
        state.tabelaPreco.itens[index].removido = true;
      } else {
        state.tabelaPreco.itens.splice(index, 1);
      }
    }

    function montaCaixaSelecao() {
      state.opcoesCaixaSelacao = [
        { valor: ETipoTabelaPreco.Cliente, Titulo: 'Clientes', descricao: 'Defina os clientes que podem ter um preço específico na tabela de preço.' },
        { valor: ETipoTabelaPreco.Regiao, Titulo: 'Regiões', descricao: 'Se a sua estratégia é mais específica por uma região defina um preço conforme a sua necessidade.' },
        { valor: ETipoTabelaPreco.Estado, Titulo: 'Estados', descricao: 'Complicações tributárias no Brasil é comum defina um preço específico para um ou mais estados.' },
        { valor: ETipoTabelaPreco.Cidade, Titulo: 'Cidades', descricao: 'Clientes e Parceiros próximos e uma logística mais segmentada pode ser a chave para o sucesso.' },

      ];
    }
    async function obterTabelaPreco(codigo:number) {
      appBase.carregando = true;
      state.tabelaPreco = await servicoTabelaPreco.obter(codigo);

      state.clientesSelecionados = [];
      if (state.tabelaPreco.clientes.length > 0) {
        state.tabelaPreco.clientes.forEach((cliente) => {
          state.clientesSelecionados.push(cliente.codigoCliente);
        });
      }

      state.regioesSelecionadas = [];
      if (state.tabelaPreco.regioes.length > 0) {
        state.tabelaPreco.regioes.forEach((regiao) => {
          state.regioesSelecionadas.push(regiao.codigoRegiao);
        });
      }

      state.estadosSelecionados = [];
      if (state.tabelaPreco.estados.length > 0) {
        state.tabelaPreco.estados.forEach((estado) => {
          state.estadosSelecionados.push(estado.codigoEstado);
        });
      }

      state.cidadesSelecionadas = [];
      if (state.tabelaPreco.cidades.length > 0) {
        state.tabelaPreco.cidades.forEach((cidade) => {
          state.cidadesSelecionadas.push(cidade.codigoCidade);
        });
      }
      appBase.carregando = false;
    }

    function limparDados() {
      state.clientesSelecionados = [];
      state.regioesSelecionadas = [];
      state.estadosSelecionados = [];
      state.cidadesSelecionadas = [];
      state.tabelaPreco = {} as ITabelaPreco;
      state.tabelaPreco.codigo = 0;
      state.tabelaPreco.status = true;
      state.tabelaPreco.padrao = false;
      state.tabelaPreco.tipo = ETipoTabelaPreco.Cliente;
      state.tabelaPreco.clientes = [];
      state.tabelaPreco.regioes = [];
      state.tabelaPreco.estados = [];
      state.tabelaPreco.cidades = [];
      state.tabelaPreco.itens = [];
      limparItemTabelaPreco();
    }

    onBeforeMount(async () => {
      montaCaixaSelecao();
      limparDados();
      const codigoTabelaPreco = obterCodigoRota();
      if (codigoTabelaPreco > 0) {
        await obterTabelaPreco(codigoTabelaPreco);
      }
    });

    function preparaPersistenciaClientes() {
      const clientes : ITabelaPrecoCliente[] = [];
      if (state.clientesSelecionados.length > 0) {
        state.clientesSelecionados.forEach((codigoCliente) => {
          const cidadeExistente = state.tabelaPreco.clientes.find((c) => c.codigoCliente === codigoCliente);
          if (cidadeExistente !== undefined) {
            clientes.push(cidadeExistente);
          } else {
            const cliente: ITabelaPrecoCliente = {
              codigo: 0, codigoTabelaPreco: state.tabelaPreco.codigo, codigoCliente,
            };
            clientes.push(cliente);
          }
        });
      }
      state.tabelaPreco.clientes = clientes;
    }

    function preparaPersistenciaEstados() {
      const estados : ITabelaPrecoEstado[] = [];
      if (state.estadosSelecionados.length > 0) {
        state.estadosSelecionados.forEach((codigoEstado) => {
          const estadoExistente = state.tabelaPreco.estados.find((c) => c.codigoEstado === codigoEstado);
          if (estadoExistente !== undefined) {
            estados.push(estadoExistente);
          } else {
            const estado: ITabelaPrecoEstado = {
              codigo: 0, codigoTabelaPreco: state.tabelaPreco.codigo, codigoEstado,
            };
            estados.push(estado);
          }
        });
      }
      state.tabelaPreco.estados = estados;
    }

    function preparaPersistenciaRegioes() {
      const regioes : ITabelaPrecoRegiao[] = [];
      if (state.regioesSelecionadas.length > 0) {
        state.regioesSelecionadas.forEach((codigoRegiao) => {
          const regiaoExistente = state.tabelaPreco.regioes.find((c) => c.codigoRegiao === codigoRegiao);
          if (regiaoExistente !== undefined) {
            regioes.push(regiaoExistente);
          } else {
            const regiao: ITabelaPrecoRegiao = {
              codigo: 0, codigoTabelaPreco: state.tabelaPreco.codigo, codigoRegiao,
            };
            regioes.push(regiao);
          }
        });
      }
      state.tabelaPreco.regioes = regioes;
    }

    function preparaPersistenciaCidades() {
      const cidades : ITabelaPrecoCidade[] = [];
      if (state.cidadesSelecionadas.length > 0) {
        state.cidadesSelecionadas.forEach((codigoCidade) => {
          const cidadeExistente = state.tabelaPreco.cidades.find((c) => c.codigoCidade === codigoCidade);
          if (cidadeExistente !== undefined) {
            cidades.push(cidadeExistente);
          } else {
            const cidade: ITabelaPrecoCidade = {
              codigo: 0, codigoTabelaPreco: state.tabelaPreco.codigo, codigoCidade,
            };
            cidades.push(cidade);
          }
        });
      }
      state.tabelaPreco.cidades = cidades;
    }

    async function salvar() {
      appBase.resposta = {} as IResposta;
      preparaPersistenciaClientes();
      preparaPersistenciaRegioes();
      preparaPersistenciaEstados();
      preparaPersistenciaCidades();
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Tabela de Preço.');

      if (state.tabelaPreco.codigo === 0) {
        appBase.resposta = await servicoTabelaPreco.incluir(state.tabelaPreco);
      } else {
        appBase.resposta = await servicoTabelaPreco.atualizar(state.tabelaPreco);
      }

      apresentarRespostaRedirecionamento('TabelaPrecos');
    }

    return {
      appBase,
      state,
      salvar,
      ETipoTabelaPreco,
      adicionarItemTabelaPreco,
      itemAlterado,
      removerItemTabelaPreco,
      UtilitarioGeral,
    };
  },
});
