
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useAppBase } from '@/core/composables/AppBase';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoProduto from '@/servicos/ServicoProduto';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';

export default defineComponent({
  name: 'ComboProdutoDefinicao',
  props: {
    multiplaSelecao: {
      type: Boolean,
      default: false,
    },
    codigo: {
      type: Number || undefined,
    },
    nome: {
      type: String,
      default: '',
    },
    codigoProduto: {
      type: Number,
      default: 0,
    },
    codigos: {
      type: Array as () => number[],
      default: () => [],
    },
    ignorarCodigos: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite o nome do produto, código SKU, código de Barras ou Código do ERP',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigo', 'update:codigos', 'update:nome', 'update:codigoProduto', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesBuscaRapida } = useAppBase();

    let debounce = 0;
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      opcoesProdutos: [] as IOption[],
      itensBuscaRapida: [] as IItemBuscaRapida[],
      buscando: false,
      digitando: false,
    });

    const computedNome = computed({
      get: () => props.nome,
      set: (val: string) => {
        emit('update:nome', val);
      },
    });

    const computedCodigoProduto = computed({
      get: () => props.codigoProduto,
      set: (val: number) => {
        emit('update:codigoProduto', val);
      },
    });

    async function obterPorCodigo(valor: any) {
      if (state.digitando) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.multiplaSelecao) {
        if (props.codigos.length === 0) { return; }
      } else if (props.codigo === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.itensBuscaRapida)) {
        if (props.multiplaSelecao) {
          const listaCodigosConsulta = state.itensBuscaRapida.map((c) => c.codigo);
          const diferencas = props.codigos.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const definicaoProduto = state.itensBuscaRapida.find((c) => c.codigo === valor);
          if (definicaoProduto !== undefined) {
            computedNome.value = definicaoProduto.textoIdentificacao;
            computedCodigoProduto.value = Number(definicaoProduto.informacaoAdicional);
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.opcoesProdutos = [];
      const parametrosBuscaRapida: IParametrosBuscaRapida = { apenasAtivos: false };
      parametrosBuscaRapida.parceiros = [];
      parametrosBuscaRapida.valor = '';
      parametrosBuscaRapida.valores = [];
      parametrosBuscaRapida.filtrarPorVariosCodigos = false;
      parametrosBuscaRapida.filtrarPorCodigo = false;
      parametrosBuscaRapida.quantidadeRegistros = 10;

      if (props.multiplaSelecao) {
        parametrosBuscaRapida.filtrarPorVariosCodigos = true;
        parametrosBuscaRapida.valores = valor;
      } else {
        parametrosBuscaRapida.filtrarPorCodigo = true;
        parametrosBuscaRapida.valor = valor;
      }

      state.itensBuscaRapida = await servicoProduto.buscaRapidaDefinicao(parametrosBuscaRapida);
      state.opcoesProdutos = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      if (!UtilitarioGeral.validaLista(state.opcoesProdutos)) {
        if (props.multiplaSelecao) {
          emit('update:codigos', undefined);
        } else {
          emit('update:codigo', undefined);
        }
      } else {
        const definicaoProduto = state.itensBuscaRapida.find((c) => c.codigo === valor);
        if (definicaoProduto !== undefined) {
          computedNome.value = definicaoProduto.textoIdentificacao;
          computedCodigoProduto.value = Number(definicaoProduto.informacaoAdicional);
        }
      }
    }

    async function pesquisar(valor: any) {
      state.digitando = true;

      state.buscando = true;
      state.opcoesProdutos = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosBuscaRapida: IParametrosBuscaRapida = { valor, apenasAtivos: true };
        parametrosBuscaRapida.parceiros = [];
        parametrosBuscaRapida.valores = [];
        parametrosBuscaRapida.filtrarPorVariosCodigos = false;
        parametrosBuscaRapida.filtrarPorCodigo = false;
        parametrosBuscaRapida.quantidadeRegistros = 10;

        state.itensBuscaRapida = await servicoProduto.buscaRapidaDefinicao(parametrosBuscaRapida);
        if (props.ignorarCodigos.length > 0) {
          props.ignorarCodigos.forEach((codigo) => {
            state.itensBuscaRapida = state.itensBuscaRapida.filter((c) => c.codigo !== codigo);
          });
        }
        state.buscando = false;
        state.opcoesProdutos = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      }, 600);
    }

    function obterProduto():any {
      if (props.codigo === 0) {
        return undefined;
      }
      obterPorCodigo(props.codigo);
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterProduto(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    function obterProdutos():number[] {
      obterPorCodigo(props.codigos);
      return props.codigos;
    }

    const computedCodigos = computed({
      get: () => obterProdutos(),
      set: (val: number[]) => {
        emit('update:codigos', val);
      },
    });

    function changeMultiplos() {
      emit('change', props.codigos);
    }

    function change() {
      emit('change', props.codigo);
    }

    return {
      props,
      state,
      computedCodigo,
      computedCodigos,
      pesquisar,
      change,
      changeMultiplos,
    };
  },
});
